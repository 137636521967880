.UserPageTemplate {
  span.lable {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--main);
    font-weight: 700;
    margin-bottom: 4px;
  }
  .option {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }

  .BorderBox {
    margin-bottom: 32px;
  }
}
