.EducationPermissions {
  .titleBox {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    cursor: pointer;

    i,
    svg {
      //   font-size: 24px;
      margin: 0 4px;
    }
  }
}
