.ScenarioItem {
  background-color: var(--bgSub);
  border: 1px solid var(--bgSub);

  .coverImage {
    // height: 300px;
    background-position: center;
    background-size: 105%;
    transition: 0.15s;

    overflow: hidden;

    .inner {
      // background: #eee2;
      background: linear-gradient(transparent, 40%, rgba(0, 0, 0, 0.805));
      padding: 12px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      // padding-top: 150px;

      cursor: pointer;
      // backdrop-filter: blur(32px);

      h2.title {
        font-size: 20px;
        color: #fff;
        margin-bottom: -4px;
      }

      .duration {
        font-size: 13px;
        color: #eee;
        text-transform: lowercase;
      }

      .MoreOptionsButton {
        z-index: 1000;
      }
      button {
        background: #222b;
        border: none;
        border-radius: 999px;
        height: 42px;
        width: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;
        margin-right: 0;
        margin-bottom: 0;

        i,
        svg {
          margin-left: 2px;
          color: #fff;
          font-size: 20px;
        }

        &.play {
          transition: 0.6s;
          background: #20bf6b;

          &:hover {
            transition: 0.4s;
            transform: scale(1.1);
          }
        }
        &.download {
          transition: 1.2s;
          &:hover {
            background: #000e;
          }
        }
        &:last-of-type {
          margin-right: 0;
        }
      }

      span.description {
        font-size: 13px;
        font-weight: 400;
        line-height: 1.15;
        color: #cccc;
      }

      span.inner {
        background: #333;
      }
    }

    &:hover {
      background-position: center;
      background-size: 100%;
      transition: 0.4s;

      .inner {
        transition: 0.2s;
        backdrop-filter: blur(0px);
      }

      // button.play {
      //   transform: scale(1.1) translateY(-2px);
      //   transition: 0.2s;
      // }
    }
  }
  span.id {
    font-size: 10px;
    opacity: 0.4;
    transform: translateY(-14px);
  }
}
