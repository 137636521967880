.Sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100%;

  // min-width: 300px !important;

  background: var(--bgSub);
  padding: 0;

  // * {
  //   align-self: flex-start;
  // }

  .inner {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    min-width: 70%;
    .EducationList {
      .EducationListItem,
      a {
        font-size: 18px;
        font-weight: 700;
        display: block;
        text-decoration: none;
        color: #0772cf;

        &.black {
          font-size: 20px;

          color: var(--text2);
        }

        transition: 0.4s;

        &:hover {
          transform: translateX(4px);
          transition: 0.2s;
        }
        &:focus {
          transform: translateX(6px);
          transition: 0.1s;
        }
      }
    }
    button {
      margin-bottom: 12px;
    }
  }
}
