.DetailTags {
  display: flex;
  flex-direction: row;

  div {
    color: #fff;

    font-weight: 700;
    font-size: 14px;
    padding: 0px 8px;
    margin-bottom: 2px;

    display: inline-block;

    &.level {
      background: var(--green);
    }

    &.code {
      background: var(--main);
    }

    &.icon {
      // background: var(--text1);
      background: var(--bg);
      i,
      svg {
        color: var(--text1);
      }
    }
  }
}
