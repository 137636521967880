.PersonBox {
  padding: 0 12px;
  //   background: var(--bgSub);
  border-left: 12px solid var(--bgSub);
  .image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    border-radius: 2px;

    background-color: var(--main);

    border-width: 1px;
    border: 1px solid var(--bgSub);

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
}
