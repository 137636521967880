.Header {
  // width: 100vw;

  .items {
    flex: 1;

    display: flex;
    flex-direction: row-reverse;
    // justify-content: center;
    align-items: center;

    a {
      text-decoration: none;
      font-weight: 400;
      color: #515760;
      padding: 16px 16px 16px 0;
    }

    i,
    svg {
      font-size: 12px;

      &.big {
        font-size: 24px;
      }
    }
  }
}
