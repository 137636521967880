.SidebarPageTemplate {
  .MobileHeader {
    position: fixed;
    z-index: 9999;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;

    top: 0;
    bottom: 0;

    background: var(--bgSub);

    .Logo svg {
      height: 32px;
    }

    i,
    svg {
      font-size: 24px;
    }
  }

  .headerTopPush {
    height: 48px;
  }

  .sidebarWrapperClass {
    padding: 0;
  }

  height: 100vh;

  .sidebarPageContent {
    // max-width: 50%;
    height: 100vh;

    overflow-y: auto;
    overflow-x: hidden;
  }
}
