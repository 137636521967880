.WebViewPage {
  .overlay {
    position: absolute;
    z-index: 999999999999999;

    margin: 16px;

    button {
      margin: 0;

      background: #fffb;
      padding: 10px 12px;
      border-radius: 2px;
      overflow: hidden;

      i,
      svg {
        margin-right: 4px;
      }
    }
  }

  .menu {
    .back {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  display: flex;
  flex-direction: column;
  height: 100vh;

  iframe {
    width: 100vw;
    flex: 1;
  }

  .menu {
    color: #fff;
    padding: 16px 24px;
    font-size: 20px;
    font-weight: 600;
    background-color: #000;

    display: flex;
    justify-content: space-between;
    align-items: center;

    span.elapsedTime {
      font-weight: 200;
      color: #888;
    }

    button {
      padding: 0;
      background: transparent;
      margin: 0;
      padding: 2px 4px;

      i,
      svg {
        // height: 32px;
        // width: 32px;
        font-size: 24px;
        padding: 0;
        margin: 0;
      }
    }
  }
}
