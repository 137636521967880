.ErrorText {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: var(--red);
  padding: 16px;

  * {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  i,
  svg {
    color: var(--red_);
    font-size: 64px;
  }

  span.title {
    font-weight: 600;
    font-size: 16px;
    color: var(--red_);
  }
  span.errorCode {
    font-weight: 400;
    font-size: 13px;
    color: var(--red_);
    opacity: 0.8;
  }
}
