body {
  background: var(--bg);
  color: var(--text2);
  overflow-y: auto;
  overflow-x: hidden;
}

.cursor-pointer,
.cursor-pointer * {
  cursor: pointer !important;
}

.App {
  height: 100vh;
  overflow: hidden;
}

a {
  color: inherit;
}
button {
  border: none;
  background: var(--main);
  color: var(--main_) !important;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 12px 16px;
  border: none;
  border-radius: 0px;
  font-weight: 600;
  font-size: 16px;
  color: var(--text2);

  &.sub {
    background: var(--sub);
    color: var(--sub_);
  }

  &.small {
    background: var(--main);
    color: var(--main_);
    padding: 1px 4px;
    padding-right: 8px;
    font-size: 14px;
    text-transform: uppercase;
  }

  &.red {
    background: var(--red);
    color: var(--red_);
  }

  i,
  svg {
    margin-right: 8px;
  }
}

.row {
  padding: 0;
  margin: 0;
}

input {
  padding: 8px 16px;
  flex: 1;
  width: 100%;
  background: var(--bgSub);
  color: var(--text3);
  border: none;
  outline: none;
  margin-bottom: 12px;

  &:-webkit-autofill {
    background: var(--bgSub) !important;
  }

  &:autofill {
    background: var(--bgSub) !important;
  }
}
