.UserListPage {
  table {
    border-radius: 0px;
    overflow: hidden;

    thead {
      background-color: var(--main);
      color: var(--main_);

      th {
        padding: 12px;
        font-weight: 400;
      }
    }
  }
}
