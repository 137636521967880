.DocumentList {
  .DocumentItem {
    display: flex;
    flex-direction: row;
    padding: 12px 12px;
    border-radius: 12px;
    background: var(--bgSub);
    cursor: pointer;

    a {
      display: flex;
      align-items: baseline;

      i,
      svg {
        margin-left: 4px;
        margin-right: 12px;
        font-size: 18px;
      }
    }
  }
}
