.ScenarioGroupFilter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .ScenarioGroupFilterItem {
    flex: 1;
    padding: 0 4px 4px 0;

    ::selection {
      background-color: transparent;
    }

    .inner {
      display: flex;
      align-items: center;
      transition: 0.8s;
      cursor: pointer;
      border-radius: 2px;
      padding: 12px 8px;
      background: var(--bgSub);
      color: var(--text1);

      i,
      svg {
        display: block;
        color: var(--text1);
        font-size: 20px;
        margin-right: 6px;
      }

      &:hover {
        background-size: 100%;

        transition: 0.15s;
        transform: scale(1.01);
        background: var(--bgSub);
        &.isActive {
          background: var(--main);
          color: #eee;
        }
      }

      .title {
        font-size: 16px;
        display: block;
        font-weight: 800;
      }
      .counter {
        font-size: 14px;
        display: block;
        font-weight: 500;
        opacity: 0.7;
      }

      &.isActive {
        background: var(--main);
        color: var(--main_);

        i,
        svg {
          color: var(--main_);
        }
      }
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}
