.FixedLegalNotice {
  position: fixed;
  bottom: 2px;
  right: 2px;
  font-size: 14px;

  a {
    padding: 2px;
    color: var(--text4);
    opacity: 0.75;
  }
}
