.ScenarioPermission {
  display: flex;
  flex-direction: row;
  background: var(--bgSub);
  height: 64px;
  align-items: center;
  //   justify-content: center;

  cursor: pointer !important;

  * {
    cursor: pointer !important;
  }

  div.img {
    background-position: center;
    background-size: cover;
    height: 64px;
    width: 92px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  > .content {
    flex: 1;
    height: 64px;
  }

  h3 {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .Checkbox {
    margin-right: 16px;
    i,
    svg {
      font-size: 32px;
    }
  }

  opacity: 0.5;

  &.isActive {
    opacity: 1;
  }

  &.Error {
    opacity: 1;
    background-color: var(--red);
  }
}
