.Logo {
  display: flex;
  //   justify-content: center;
  flex-direction: row;
  align-items: center;

  text-decoration: none;
  font-weight: 800;

  svg {
    transition: 0.3s;
    height: 80px;
  }

  div {
    span.hlfs {
      display: block;
      color: var(--main);
      line-height: 1;
      font-size: 19px;
    }
    span.vr {
      display: block;
      color: var(--text2);
      line-height: 1;
      font-size: 22px;
    }
  }

  &:hover {
    svg {
      transform: scale(1.1);
      transition: 0.3s;
    }
  }
}
