footer.Footer {
  display: flex;

  a {
    margin-right: 12px;
    text-decoration: none;
    font-size: 18px;
    color: var(--text1);
    font-weight: 700;
  }

  padding: 32px 0;
}
