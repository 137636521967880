body.dark-theme img {
  filter: brightness(0.8) contrast(1.2);
}

html {
  --text1: #000;
  --text2: #2e2e2e;
  --text3: #4e4e4e;
  --text4: #6e6e6e;
  --text5: #bfbfbf;

  --bg: #fff;
  --bgSub: #eee;
  --bgBlue: #e3e9ef;
  --bgRed: #f9f1f1;

  --main: #003493;
  --main50: #456eba;
  --main_: #eee;
  --sub: #0a3fc2;
  --sub_: #fff;

  --red: #cf1717;
  --red_: #ffffff;
  --orange: #f27c0d;
  --green: #33cc34;
}

@media (prefers-color-scheme: dark) {
  img {
    filter: brightness(0.8) contrast(1.2);
  }

  html {
    --text1: #fff;
    --text2: #e2e2e2;
    --text3: #c2c2c2;
    --text4: #a2a2a2;
    --text5: #4a4a4a;

    --bg: #000;
    --bgBlue: #1f2122;
    --bgSub: #121212;

    --main: #003493;
    --main50: #313e57;
    --main_: #eee;
    --sub: #0a3fc2;
    --sub_: #fff;
  }
}
body {
  background: var(--bg);
}
