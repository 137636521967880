.WarnBox {
  background-color: #eeee;
  border-radius: 4px;
  padding: 12px;
  padding-top: 1ßpx;
  line-height: 1.15;
  margin: 12px 0;
  span.notice {
    font-size: 13px;
    color: #222;

    span,
    i,
    svg {
      font-size: 14px;
      color: rgb(221, 0, 0);
    }

    .pill {
      background-color: rgb(221, 0, 0);
      height: 4px;
      width: 128px;
      border-radius: 2px;
      // margin-top: 4px;
      margin-bottom: 4px;
    }

    span {
      font-weight: 800;
      display: block;
    }
  }
}
