.ScenarioOverviewPageTemplate {
  .coverImage {
    background-color: var(--bgSub);
    background-position: center center;
    background-size: cover;
  }

  button.play {
    // background-color: var(--green);

    &.disabled {
      opacity: 0.5;
    }
  }
}
