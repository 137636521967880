.ImportantWarnings {
  background: var(--red);
  color: var(--red_);
  padding: 16px 24px;
  border-radius: 2px;
  margin-bottom: 32px;

  span.title {
    font-size: 22px;
    font-weight: 600;
    display: block;
  }
  span.desc {
    font-size: 18px;
  }
}
