.WelcomePage {
  .ScenarioListItem {
    padding: 64px 12px;
    text-align: center;
    background: var(--bgSub);
    border-radius: 2px;

    h2 {
      margin: 0;
      font-weight: 900;
    }

    transition: 0.5s;

    &:hover {
      cursor: pointer;
      background: var(--main);
      h2 {
        color: #fff;
      }
      transition: 0.2s;
    }
  }
}
