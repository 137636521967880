.TextPageTemplate {
  .content {
    max-height: 100vh;
    overflow-x: auto;

    h1 {
      font-size: 32px;
    }

    .inner {
      max-width: 1000px;
      img {
        margin-bottom: 12px;
      }
    }
  }
}
