.ServicePage {
  .icon {
    font-size: 32px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .size {
    font-size: 11px;
  }
}
