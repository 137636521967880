.DefaultLoader {
  //   position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  i,
  svg {
    position: absolute;
    font-size: 64px;
    color: var(--main);
  }
}
