body {
  margin: 0;
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.small,
small {
  font-size: 14px;
}

.error {
  color: var(--red);
  font-size: 20px;
  font-weight: 900;
}

p {
  font-size: 18px; /* Ändere die Schriftgröße auf 20px */
  color: var(--text3);
  white-space: pre-wrap;
}

a {
  text-decoration: none;
}

h1,
.h1 {
  color: var(--text1);
  font-weight: 700;
  font-size: 34px; /* Ändere die Schriftgröße auf 34px */
}

h2,
.h2 {
  color: var(--text2);
  font-weight: 800;
  font-size: 20px; /* Ändere die Schriftgröße auf 16px */
}

h3,
.h3 {
  color: var(--text3);
  font-size: 16px; /* Ändere die Schriftgröße auf 14px */
  margin-bottom: 0;
}

h4,
.h4 {
  color: var(--text4);
  font-size: 15px; /* Ändere die Schriftgröße auf 20px */
}

h5,
.h5 {
  color: var(--text3);
  font-size: 20px; /* Ändere die Schriftgröße auf 20px */
}

h6,
.h6 {
  color: var(--text3);
  font-size: 34px; /* Ändere die Schriftgröße auf 34px */
}

label {
  display: block;
}
