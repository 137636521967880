.UserItem {
  td,
  th {
    padding: 12px;
  }

  td {
    &.name {
      font-size: 16px;
      flex: 1;
    }

    &.email {
      opacity: 0.8;
      text-transform: uppercase;
    }
  }

  &:nth-of-type(odd) {
    background: var(--bgSub);
  }
  border-left: 4px solid transparent;

  transition: 0.1s;
  cursor: pointer;
  &:hover {
    // background: var(--main);
    // color: var(--main_);
    transition: 0.3s;
    // transform: scaleY(1.1) scaleX(1.03) translateX(8px);
    // transform: translateX(8px);
    border-left: 4px solid var(--main);
    background: var(--bgBlue);
  }
}
