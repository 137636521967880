.LoginPage {
  .inner {
    padding: 32px;
    max-width: 720px;

    display: flex;
    flex-direction: column;

    form {
      display: flex;
      flex-direction: column;
      input {
        margin-bottom: 16px;
        padding: 12px;
        font-size: 18px !important;
      }
      button {
        background: var(--main);
        color: #fff;
        font-size: 18px;
        margin-bottom: 32px;

        &.Loading {
          background: var(--main50);
        }
      }
    }
    .moreOptions {
      display: flex;
      flex-direction: column;
      a {
        margin-right: 16px;
      }
    }
  }
}
