.NoticeBox {
  display: block;
  margin-bottom: 12px;

  p {
    font-size: 16px;
    color: var(--text3);
    white-space: pre-wrap;
  }
}
